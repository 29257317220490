/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

$(function () {
    initBrandsSlider();
    init_authorize();
    init_register();
    clearViewedProducts();
    initListingAddToCart();
    initModColorsTooltips();
    init_quantity_control();

    $(window).on('resize', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            initBrandsSlider();
            initModColorsTooltips();
        }, 250);
    });


    var docked = false;
    var last_scroll_top = 0;
    var last_direction = 'up';

    function header_fixed() {
        if ( $(window).width() > 992 ){
            var possition = 40;

            if (!docked && $(document).scrollTop() >= possition && last_direction != 'up') {
                $('body').addClass('scroll-down');
                docked = true;
            } else if(docked && ($(document).scrollTop() <= possition || last_direction == 'up') ) {
                $('body').removeClass('scroll-down');
                docked = false;
            }
        }
    }

    header_fixed();
    filter_scroll_notice();

    $(window).scroll(function(){
        var document_top = $(document).scrollTop();
        var direction = 'up';
        if (last_scroll_top < document_top) {
            direction = 'down';
        }
        last_scroll_top = document_top;
        last_direction = direction;
        header_fixed();
        filter_scroll_notice();
    });

    $(window).on('click', function(e){
        if ($('#profile-dropdown').hasClass('active')) {
            profile_container = $("#profile-dropdown");
            if (!$('.js-toggle-profile').is(e.target) && $('.js-toggle-profile').has(e.target).length === 0) {
                if (!profile_container.is(e.target) && profile_container.has(e.target).length === 0) {
                    toggleProfileMenu();
                }
            }
        }
    });
    
    $(document).on('click', '.filter-scroll-notice button', function(e) {
        e.preventDefault();
        scrollToElement('.header-filter-desktop');
    });

    $(document).on('click', '.need2login-demo', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/login',
            callback: function(html) {
                doModal(html, 'modal-auth');
            }
        });
    });

    //todo: ar reikes?
    $(document).on('click', '.need2auth-demo', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/authorize',
            callback: function(html) {
                doModal(html, 'modal-auth');
            }
        });
    });

    //todo: ar reikes?
    $(document).on('click', '.need2register-demo', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/register',
            callback: function(html) {
                doModal(html, 'modal-auth');
            }
        });
    });

    if (get_vw() > screen_lg) {
        $('.menu.main > li.dropdown').hover(function () {
            $('.header-overlay').addClass('active');
        }, function () {
            $('.header-overlay').removeClass('active');
        });
    } else {
        $('.js-toggle-dropdown').click(function (e) {
            e.preventDefault();

            $(this).closest('.dropdown').find('> .submenu').toggleClass('active');
        });
    }

    $('.js-toggle-main-nav').click(function (e) {
        e.preventDefault();

        $('.main-nav').toggleClass('active');
        $('.submenu').removeClass('active');
    });

    $('.editor_type_banners_slider').each(function () {
        tns({
            container: $(this).find('.banners_slider')[0],
            items: 1,
            mode: 'gallery',
            navPosition: 'bottom',
            controlsContainer: $(this).find('.custom-controls')[0],
            navContainer: $(this).find('.custom-nav')[0],
            autoplay: false,
            loop: true,
            autoplayButtonOutput: false,
            nav: true,
            controls: true,
        });
    });

    $('.js-toggle-search').click(function (e) {
        e.preventDefault();
        let suggestions = $('#search-suggestions');

        suggestions.toggleClass('active');

        if (suggestions.hasClass('active')) {
            $('.quick-search-wrp input[name="search"]').val('');
            $('#search-suggestions-results').empty();
        }
    });

    $('.js-toggle-profile').click(function (e) {
        e.preventDefault();

        toggleProfileMenu();
    });

    $(document).on('click', '.js-toggle-filters', function(e) {
        e.preventDefault();
        $('#filters').toggleClass('active');
    });

    $(document).on('click', '.js-toggle-sorting', function(e) {
        e.preventDefault();
        $('#sorting').toggleClass('active');
    });

    $(document).on('click', '.page-breadcrumb .dots', function(e) {
        e.preventDefault();
        $(this).hide();
        $(this).parent().find('.link-breadcrumb').addClass('visible');
    });

    var search_delay = null;
    $(".quick-search-form input[name='search']").keyup(function () {
        let val = $(this).val();
        if (val.length > 2) {
            if (search_delay != null) clearTimeout(search_delay);
            search_delay = setTimeout(function () {
                $("#search-suggestions-results").html(loader);
                $('#search-suggestions').addClass('active');

                search_request = $.ajax({
                    type: 'POST',
                    url: '?display=content_types/search/suggestions.tpl',
                    data: {q: val},
                    success: function (data) {
                        $("#search-suggestions-results").html(data);
                    }
                });
            }, 1000);
        } else {
            if (val.length > 0) {
                $("#search-suggestions-results").html(moreChars);
                $('#search-suggestions').addClass('active');
            } else {
                $('#search-suggestions').removeClass('active');
            }
        }
    });

    $('html body').click(function (e) {
        if (!$(e.target).closest('.quick-search-wrp').length && !$(e.target).is('.quick-search-wrp') && get_vw() > screen_lg_min) {
            $('#search-suggestions').removeClass('active');
        }
    });

    // filtrams papildomi eventai, kad toggle'intu ant parent conteineriu .open klase
    let filterCollapse = document.querySelectorAll('.filter-item');

    filterCollapse.forEach(filterCollapseEl => {
        filterCollapseEl.addEventListener('show.bs.collapse', function () {
            $(this).closest('.filter-item').addClass('open');
        })
        filterCollapseEl.addEventListener('hide.bs.collapse', function () {
            $(this).closest('.filter-item').removeClass('open');
        })
    })

    // kopijavimas i clipboard. Veikia tik su https
    $('[data-clipboard-title]').click(function (e) {
        e.preventDefault();
        let copyText = $(this).data('clipboard-title');
        let copyUrl = $(this).data('clipboard-url');

        if (navigator.clipboard && copyText && copyUrl) {
            navigator.clipboard.writeText(copyUrl).then(() => {
                const tooltip = new bootstrap.Tooltip(this, {
                    title: $(this).data('clipboard-title'),
                    trigger: 'manual'
                });

                tooltip.show()

                setTimeout(function () {
                    tooltip.hide()
                }, 1800);
            });
        }
    });

    // Checkout prekiu listo pilno height toggle'as mobile versijoje
    if (get_vw() < screen_md) {
        $('.js-toggle-list').click(function (e) {
            e.preventDefault();

            let checkoutProductsList = document.getElementById('checkout-products-list')
            let checkoutProductsListCollapse = new bootstrap.Collapse(checkoutProductsList, {
                toggle: false
            });

            if ($(this).hasClass('clicked')) {
                $(this).text($(this).data('show-title'));
                checkoutProductsListCollapse.hide();
            } else {
                $(this).text($(this).data('hide-title'));
                checkoutProductsListCollapse.show();
            }

            $(this).toggleClass('clicked');
        });
    }

    // Checkout prekiu listo dalinio height toggle'as desktop versijoje
    $(document).on('click', '.products-summary-wrp .show-more', function (e) {
        e.preventDefault();

        let parent = $(this).closest('.products-summary-wrp');
        let innerH = parent.find('.inner').outerHeight();
        let list = parent.find('.checkout-products-list');
        let spanText = $(this).find('span');
        let icon = $(this).find('i');

        if(parent.hasClass('show')){
            spanText.text($(this).data('show-title'));
            icon.removeClass('fa-arrow-up').addClass('fa-arrow-down');
            list.removeAttr('style');
        }else{
            spanText.text($(this).data('hide-title'));
            icon.removeClass('fa-arrow-up').addClass('fa-arrow-up');
            list.css('max-height', innerH);
            
            summary_offset = $('.summary-step').offset();
            $('.summary-step').css('max-height', $(window).height() - summary_offset.top);
        }

        parent.toggleClass('show');
    });

    /*deliveryOptionInputs();*/

    /*$('.delivery-options input').click(function () {
        deliveryOptionInputs();
    });*/

    // Slaptazodzio input type password/text keitimas
    $(document).on('click', '.show-psw', function(e) {
        e.preventDefault();

        let parent = $(this).closest('.form-item');
        let input = parent.find('input');

        if (input.attr('type') === 'password') {
            $(this).text($(this).data('hide-title'));
            input.attr('type', 'text');
        } else {
            $(this).text($(this).data('show-title'));
            input.attr('type', 'password');
        }
    });

    // Kiekio +/- keitimas
    $(document).on('click', '.amount-actions button', function() {
        let amountEl = $(this).closest('.amount-actions').find('input[name=amount]');
        let current_amount = parseInt(amountEl.val());

        if ($(this).hasClass('plus')) {
            amountEl.val(current_amount + 1);
        } else if ($(this).hasClass('minus')) {
            if (current_amount !== 1)
                amountEl.val(current_amount - 1);
        }
    });

    // Custom file type. Parodome atskirai koks failo pavadinimas ikeltas
    /*$('input[type="file"]').change(function() {
        let parent = $(this).closest('.file');
        let filename = this.files[0].name;


        parent.find('.file-name').text(filename);
    });*/

    // Skirtingi inputai grazinimo priezasciai pasirinkus
    $('.reason-select').change(function(e) {
        e.stopImmediatePropagation();

        let parent = $(this).closest('.reason');
        let selectVal = $(this).val();
        let formItems = parent.find('.reason-selectable:not(.form-item-file)');
        let formFileItems = parent.find('.reason-selectable.form-item-file');

        selectedOption = $('option:selected', this);
        $('textarea', formItems).attr('placeholder', selectedOption.data('placeholder'));
        if (selectedOption.data('file') == '1') {
            formFileItems.show().find('.file').removeClass('disabled').find('input[type="file"]').removeAttr('disabled');
        } else {
            formFileItems.hide().find('.file').addClass('disabled').find('input[type="file"]').attr('disabled', 'disabled');

        }
        formItems.show();

        /*formItems.each(function () {
            if ($(this).hasClass(selectVal)) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });*/
    });

    // star reitingavimas
    $('.js-star-rating:not(.disabled) i')
        .on('mouseover', function(e) {
            let rating = $(e.target).data('rating');
            let parent = $(e.target).closest('.js-star-rating');
            // remove error
            
            if (!parent.hasClass('disabled')) {
                parent.removeClass('has_error');
                // fill all the stars
                parent.find('i').removeClass('fa-regular').addClass('fa-solid');
                // empty all the stars to the right of the mouse
                parent.find('i#rating-' + rating + ' ~ i:not(.selected)').removeClass('fa-solid').addClass('fa-regular');
    
                parent.find('span').text(rating + '.0');
                parent.find('.rating-in-words > div').hide();
                parent.find('.rating-in-words .rating-' + rating).show();
            }
        })
        .on('mouseleave', function (e) {
            let parent = $(e.target).closest('.js-star-rating');

            // empty all the stars except those with class .selected
            parent.find('i:not(.selected)').removeClass('fa-solid').addClass('fa-regular');

            if(!parent.find('i.selected').length){
                parent.find('span').text('0.0');
            }else{
                parent.find('span').text(parent.find('i.selected').length + '.0');
            }
            parent.find('.rating-in-words > div').hide();
            parent.find('.rating-in-words .rating-' + parent.find('i.selected').length).show();
        })
        .on('click', function(e) {
            setRating($(e.target), $(e.target).data('rating'));
        });

    $(document).on('click', '#buy-together-items [data-item]', function (e) {
        e.preventDefault();
        let item = $(this).data('item');
        $('[data-card-item="'+item+'"]').toggleClass('active');
        $('.together-item[data-item="'+item+'"]').toggleClass('active');
        $('.together-item[data-item="'+item+'"] input').attr('checked', !$('[data-item="'+item+'"] input').attr('checked'));
        buyTogetherItems();
    });

    $(document).on('click', '.js-toggle-colors', function (e) {
        e.preventDefault();

        $('.modifications-colors').toggleClass('show-less');
        $('.mod-colors-show-less').toggleClass('d-none');
    });

    $(document).on('click', '.js-call-tab', function (e) {
        e.preventDefault();
        let $this = this;
        scrollToElement('.nav.details-tabs');

        setTimeout(function () {
            if (get_vw() < screen_md) {
                tabPaneMobileModal($this);
            } else {
                const triggerEl = document.querySelector('[data-bs-target="' + $($this).data('target') + '"]');
                const tabTrigger = new bootstrap.Tab(triggerEl);
                tabTrigger.show();
            }
        }, 500);
    });

    if (get_vw() < screen_md) {
        $(document).on('click', '.product-desc-details .details-tabs > li > a[data-bs-toggle="tab"]', function (e) {
            e.preventDefault();
            tabPaneMobileModal(this);
        });
    }

    $(document).on('click', '.details-tabs li a[href^="#"]', function (e) {
        e.preventDefault();
        location.hash = $(this).attr('href');
        if (get_vw() > screen_md) {
            scrollToElement('.nav.details-tabs');
        }
    });

    $(document).on('click', '.product-desc-details .tab-pane a[href^="#"], .tab-panel-modal a[href^="#"]', function () {
        let hash = $(this).attr('href');

        $('.details-tabs li a[href^="#"]').each(function (key, val) {
            if (hash == $(val).attr('href')) {
                if (get_vw() < screen_md) {
                    if ($($(val).attr('href') + '-tab-pane').length) {
                        let tabContent = $('#dynamicModal .modal-body').html();
                        $("#dynamicModal").modal('hide');

                        $('.details-tabs-content .tab-pane').each(function (key, val) {
                            if ($(val).is(':empty')) {
                                $($(val)).html(tabContent);
                            }
                        });

                        $('#dynamicModal').remove();
                        tabPaneMobileModal(val);
                    }
                } else {
                    const tabTrigger = new bootstrap.Tab(val);
                    tabTrigger.show();
                    scrollToElement('.nav.details-tabs');
                }

                window.location.hash = hash;
            }
        });
    });

    if (window.location.hash && $('.details-tabs').length) {
        if (get_vw() < screen_md) {
            tabPaneMobileModal($('[data-bs-target="' + window.location.hash + '-tab-pane"]'));
        } else {
            scrollToElement('.nav.details-tabs');
            const triggerEl = document.querySelector('[data-bs-target="' + window.location.hash + '-tab-pane"]');
            const tabTrigger = new bootstrap.Tab(triggerEl);
            tabTrigger.show();
        }
    }

    if (get_vw() > screen_md && $('.product-gallery-thumb-list').length) {
        tns({
            container: '.product-gallery-thumb-list',
            loop: false,
            axis: 'vertical',
            swipeAngle: false,
            speed: 400,
            gutter: 6,
            nav: false,
            controls: true,
            controlsText: ['<i class="fa-regular fa-angle-up"></i>', '<i class="fa-solid fa-angle-down"></i>'],
            responsive: {
                992: {
                    items: 7
                },
                1400: {
                    items: 10
                }
            }
        });
    }

    $(document).on('change keyup', '.form-item.item-has-error', function () {
        $(this).removeClass('item-has-error');
        $(this).find('.form-item-error').remove();
    });

    $(document).on('change', '#payment-options input', function () {
        $('#orders_pay .alert').remove();
    });

    $(document).on('change', '.delivery-options input', function () {
        $('#delivery-options .alert').remove();
    });

    $(document).on('change', 'input[name="rules_accepted"]', function () {
        $('.last-step .alert').remove();
    });
    
    $(document).on('change', 'input[name="privacy_accepted"]', function () {
        $('#register-form .alert').remove();
    });

    $(document).on('change keyup', '.checkbox.item-has-error', function () {
        $(this).removeClass('item-has-error');
    });
    
    if($('#orders_pay .alert').length){
        scrollToElement('#orders_pay .alert');
    }

    if($('form[name="one_step"] .form-item.item-has-error:first, form[name="one_step"] .alert:first').length){
        scrollToElement('form[name="one_step"] .form-item.item-has-error:first, form[name="one_step"] .alert:first');
    }

    $(document).on('change', '#feedback-form .answer input', function () {
        if($('.questions input').serializeArray().length == $('.questions .question').length){
            $('#feedback-form .questions .alert').remove();
        }
    });

    $(document).on('click', '#feedback-form .rating i', function () {
        $('.products-feedback-wrp .alert').remove();
    });

    if($('#feedback-form .alert').length){
        scrollToElement('#feedback-form .alert');
    }

    $(document).on('click', '.accordion[id^="duk_"] .accordion-button', function (e) {
        e.preventDefault();
        let status = $(this).attr('aria-expanded');
        let hash = $(this).attr('href');

        if (status == 'true') {
            history.pushState(null, null, hash);
        }
    });

    if (location.hash !== null && location.hash !== "" && $(location.hash + ".collapse").length) {
        let mainNavH = $('.main-nav').outerHeight();
        if (typeof mainNavH == 'undefined' || get_vw() <= screen_lg_min) mainNavH = 0;

        $('html, body').stop().animate({
            'scrollTop': $(location.hash).closest('.accordion-item').offset().top - $('[data-scroll-header]').outerHeight() - mainNavH
        }, 500, 'swing');

        $(location.hash + ".collapse").collapse("show");
    }
});